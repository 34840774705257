import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateRegion: build.mutation<UpdateRegionApiResponse, UpdateRegionApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/region/edit/${queryArg.regionId}`,
        method: 'PUT',
        body: queryArg.regionMultiLangDto,
      }),
    }),
    createRegion: build.mutation<CreateRegionApiResponse, CreateRegionApiArg>({
      query: (queryArg) => ({ url: `/api/v1/region/create`, method: 'POST', body: queryArg.regionMultiLangDto }),
    }),
    getRegionDetails: build.query<GetRegionDetailsApiResponse, GetRegionDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/region/details/${queryArg.regionId}`,
        params: { language: queryArg.language },
      }),
    }),
    getAllRegions: build.query<GetAllRegionsApiResponse, GetAllRegionsApiArg>({
      query: (queryArg) => ({ url: `/api/v1/region/all`, params: { language: queryArg.language } }),
    }),
    getAllRegionsMultiLang: build.query<GetAllRegionsMultiLangApiResponse, GetAllRegionsMultiLangApiArg>({
      query: () => ({ url: `/api/v1/region/all-multilingual` }),
    }),
    deleteRegion: build.mutation<DeleteRegionApiResponse, DeleteRegionApiArg>({
      query: (queryArg) => ({ url: `/api/v1/region/delete/${queryArg.regionId}`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateRegionApiResponse = /** status 200 OK */ object;
export type UpdateRegionApiArg = {
  regionId: number;
  regionMultiLangDto: RegionMultiLangDto;
};
export type CreateRegionApiResponse = /** status 200 OK */ object;
export type CreateRegionApiArg = {
  regionMultiLangDto: RegionMultiLangDto;
};
export type GetRegionDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetRegionDetailsApiArg = {
  regionId: number;
  language?: string;
};
export type GetAllRegionsApiResponse = /** status 200 OK */ RegionMultiLangDto[];
export type GetAllRegionsApiArg = {
  language: string;
};
export type GetAllRegionsMultiLangApiResponse = /** status 200 OK */ RegionMultiLangDto[];
export type GetAllRegionsMultiLangApiArg = void;
export type DeleteRegionApiResponse = /** status 200 OK */ object;
export type DeleteRegionApiArg = {
  regionId: number;
};
export type LocalizedStringDto = {
  language: string;
  value: string;
};
export type RegionMultiLangDto = {
  regionId?: number;
  name?: LocalizedStringDto[];
  code?: string;
  countryId: number;
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export const {
  useUpdateRegionMutation,
  useCreateRegionMutation,
  useGetRegionDetailsQuery,
  useGetAllRegionsQuery,
  useGetAllRegionsMultiLangQuery,
  useDeleteRegionMutation,
} = injectedRtkApi;
