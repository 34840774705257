import React from 'react';
import { privacyPage } from './privacyPageHtml';
import { ScrollView } from 'devextreme-react';

export default function PrivacyPage() {
  return (
    <ScrollView>
      <div dangerouslySetInnerHTML={{ __html: privacyPage }} />
    </ScrollView>
  );
}
