import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useAppSelector } from '../../redux/store';
import { FileUploader, Form, LoadPanel, Popup, ScrollView } from 'devextreme-react';
import { Position, ToolbarItem } from 'devextreme-react/popup';
import { useLanguage } from '../hooks/useLanguageHook';
import { setUserToEdit, userToEditSelector } from '../../redux/modules/user';
import { useUpdateUserMutation, useAddUserPictureMutation } from '../../api/medapp/user.generated';
import { UserNameDetailGrid, UserNameDto } from './UserNameDetailGrid';
import { useTranslation } from 'react-i18next';
import { EmailRule, Label, RequiredRule, SimpleItem, Tab, TabPanelOptions, TabbedItem } from 'devextreme-react/form';
import { useGetAllSpecialityQuery } from '../../api/medapp/speciality.generated';
import { LocalizedStringDto, useGetClinicsForCurrentUserQuery } from '../../api/medapp/clinic.generated';
import { getTranslatedValue, generateItems, generatePhotoBySize } from '../helpers';

export const EditUserDialog = () => {
  const formRef = useRef<Form>(null);
  const dispatch = useDispatch();
  const { language } = useLanguage();
  const { t } = useTranslation();
  const userTypesTranslations = t('userTypes', { returnObjects: true });

  const userToEdit = cloneDeep(useAppSelector(userToEditSelector));

  const { currentData: allSpecialities, isFetching: isFetchingSpecialities } = useGetAllSpecialityQuery({
    language: language,
  });

  const { currentData: allClinicsForCurrentUser, isFetching: isFetchingClinicsForCurrentUser } =
    useGetClinicsForCurrentUserQuery({ lang: language });

  const [updateUser] = useUpdateUserMutation();
  const [updateUserLogo] = useAddUserPictureMutation();

  const calculateSpeciality = useCallback(
    (data: { name: LocalizedStringDto[] }) => {
      return getTranslatedValue(data.name, language);
    },
    [language],
  );

  const calculateName = useCallback(
    (data: { name: LocalizedStringDto[] }) => {
      return getTranslatedValue(data.name, language);
    },
    [language],
  );

  const finish = useCallback(() => {
    const validationResult = formRef.current?.instance.validate();
    if (validationResult?.isValid && userToEdit?.id && userToEdit) {
      updateUser({
        userMultilangDto: userToEdit,
        language: language,
        userId: userToEdit.id,
      });
      dispatch(setUserToEdit(undefined));
    }
  }, [dispatch, language, updateUser, userToEdit]);

  const cancelEditing = useCallback(() => {
    dispatch(setUserToEdit(undefined));
  }, [dispatch]);

  const UserNameDetailGridRenderer = () => {
    const languages = userToEdit?.firstName?.map((name: LocalizedStringDto) => {
      return name.language;
    });
    const dataSource: UserNameDto[] = [];
    languages?.forEach((language: string) => {
      dataSource.push({
        language: language,
        firstName: getTranslatedValue(userToEdit?.firstName ?? [], language) ?? '',
        lastName: getTranslatedValue(userToEdit?.lastName ?? [], language) ?? '',
      });
    });

    return (
      <UserNameDetailGrid
        dataSource={dataSource}
        onRowUpdated={(data: UserNameDto) => {
          const dto = { ...userToEdit };
          const fn = dto.firstName?.find((fn) => fn.language === data.language);
          const ln = dto.lastName?.find((ln) => ln.language === data.language);
          if (fn) fn.value = data.firstName;
          if (ln) ln.value = data.lastName;
        }}
      />
    );
  };

  const fileUpload = useCallback(() => {
    return (
      <FileUploader
        labelText=""
        accept="image/*"
        uploadMode="useForm"
        onValueChanged={async (event) => {
          const file = event.value?.[0];
          if (file) {
            const form = new FormData();
            form.append('file', file);

            const response = await updateUserLogo({
              userId: userToEdit?.id ?? 0,
              body: form as any,
            });

            if ('data' in response) {
              const updatedUser = response.data;
              dispatch(setUserToEdit(updatedUser as any));
            }
          }
        }}
      />
    );
  }, [userToEdit, dispatch, updateUserLogo]);

  const img = useCallback((url: any) => {
    if (url.editorOptions.value) {
      return (
        <img
          src={generatePhotoBySize('small', url.editorOptions.value) ?? ''}
          alt="Logo"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      );
    }
  }, []);

  if (!userToEdit) return <div></div>;

  return (
    <Popup visible={true} resizeEnabled={true} showCloseButton={false}>
      <ScrollView>
        <LoadPanel
          visible={isFetchingSpecialities || isFetchingClinicsForCurrentUser}
          shading={true}
          shadingColor="rgba(0,0,0,.32)"
        >
          <Position my="center" at="center" of={`#EditUserForm`} />
        </LoadPanel>
        <Form id="EditUserForm" ref={formRef} formData={userToEdit}>
          <TabbedItem>
            <TabPanelOptions deferRendering={false} />
            <Tab title={`${t('createUserDialog.info')}`} colCount={2}>
              <SimpleItem dataField={'username'} isRequired editorOptions={{ readOnly: true }}>
                <Label text={`${t('createUserDialog.username')}`} />
              </SimpleItem>
              <SimpleItem dataField={'emailAddress'}>
                <Label text={`${t('createUserDialog.email')}`} />
                <EmailRule />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem dataField={'phoneNumber'}>
                <Label text={`${t('createUserDialog.phone')}`} />
              </SimpleItem>
              <SimpleItem
                dataField={'type'}
                editorType="dxSelectBox"
                isRequired
                editorOptions={{
                  items: generateItems(userTypesTranslations),
                  displayExpr: 'displayValue',
                  valueExpr: 'value',
                  searchEnabled: true,
                  readOnly: true,
                }}
              >
                <Label text={`${t('createUserDialog.type')}`} />
              </SimpleItem>
              <SimpleItem
                dataField={'specialityIds'}
                editorType="dxTagBox"
                isRequired
                editorOptions={{
                  stylingMode: 'outlined',
                  items: allSpecialities,
                  displayExpr: calculateSpeciality,
                  valueExpr: 'id',
                  searchEnabled: true,
                }}
              >
                <Label text={`${t('createUserDialog.specialities')}`} />
              </SimpleItem>
              <SimpleItem
                dataField={'clinicIds'}
                editorType="dxTagBox"
                isRequired
                editorOptions={{
                  stylingMode: 'outlined',
                  isRequired: true,
                  items: allClinicsForCurrentUser,
                  displayExpr: calculateName,
                  valueExpr: 'id',
                  searchEnabled: true,
                }}
              >
                <Label text={`${t('createUserDialog.clinic')}`} />
              </SimpleItem>
            </Tab>
            <Tab title={`${t('createUserDialog.translations-names')}`}>
              <SimpleItem render={UserNameDetailGridRenderer} />
            </Tab>
            <Tab title={`${t('createUserDialog.profileInfo')}`}>
              <SimpleItem dataField="profilePicture" render={img} label={{ visible: false }} />
              <SimpleItem render={fileUpload} />
            </Tab>
          </TabbedItem>
        </Form>
      </ScrollView>
      <ToolbarItem
        widget={'dxButton'}
        toolbar={'bottom'}
        location={'after'}
        options={{
          text: `${t('buttons.confirm')}`,
          elementAttr: { 'aria-label': 'confirm' },
          onClick: () => {
            const validationResult = formRef.current?.instance.validate();
            if (validationResult?.isValid) {
              finish();
            }
          },
        }}
      />
      <ToolbarItem
        widget={'dxButton'}
        toolbar={'bottom'}
        location={'after'}
        options={{
          text: `${t('buttons.cancel')}`,
          elementAttr: { 'aria-label': 'cancel' },
          onClick: () => cancelEditing(),
        }}
      />
    </Popup>
  );
};
