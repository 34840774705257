import { useCallback } from 'react';
import { useGetAllSchedulesQuery } from '../../api/medapp/schedule.generated';
import { useGetAllUserMultiLangQuery } from '../../api/medapp/user.generated';
import { useGetClinicsForCurrentUserQuery } from '../../api/medapp/clinic.generated';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { LoadPanel, ScrollView } from 'devextreme-react';
import { Column, DataGrid, MasterDetail, LoadPanel as LoadPanelProp, FilterRow } from 'devextreme-react/data-grid';
import { Schedule, WorkingHours } from '../../api';
import { WorkingHoursDetailGrid } from '../WorkingHoursDetailGrid/WorkingHoursDetailGrid';
import { ScheduleDTO } from '../../api/medapp/generated';
import { DateTimeFormatOptions } from 'luxon';
import { renderProfileImage } from '../helpers';
import { useLanguage } from '../hooks/useLanguageHook';

const dateTimeOptions: DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const WorkingHoursGrid = () => {
  const { language } = useLanguage();

  const { currentData: allUsers, isFetching: isFetchingUsers } = useGetAllUserMultiLangQuery(
    { userType: 'SPECIALIST' },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { currentData: allSchedules, isFetching: isFetchingSchedules } = useGetAllSchedulesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const { currentData: allClinicsForCurrentUser, isFetching: isFetchingClinicsForCurrentUser } =
    useGetClinicsForCurrentUserQuery({ lang: language });

  const { t } = useTranslation();

  const calculateUserDisplayExpr = useCallback(
    (data: ScheduleDTO) => {
      const user = allUsers?.find((u) => u.id === data.userId);

      const firstName = user?.firstName?.find((name) => name.language === `${language}`)?.value;
      const lastName = user?.lastName?.find((name) => name.language === `${language}`)?.value;
      return firstName + ' ' + lastName;
    },
    [language, allUsers],
  );

  // We can reuse function getClinicName in other grids -> when we need to get the current data (for example: clinicName, firstName, lastName, scheduleDetails, etc. ) from Object/Record/Arry via provided id !!!
  const getClinicName = useCallback(
    (data: WorkingHours) => {
      const clinicId = allClinicsForCurrentUser?.find((clinic: any) => clinic.id === data.clinicId);
      const clinicName = clinicId?.name?.find((name) => name.language === `${language}`)?.value;
      return clinicName;
    },
    [allClinicsForCurrentUser, language],
  );

  const detailView = useCallback((e: { data: { data: ScheduleDTO } }) => {
    const { data } = e.data;
    return <WorkingHoursDetailGrid schedule={data} />;
  }, []);

  const schedulePeriod = useCallback(
    (data: Schedule) => {
      const startTime = data.startTime;
      const endTime = data.endTime;
      const result = `${new Date(String(startTime)).toLocaleDateString(language, dateTimeOptions)} - ${new Date(
        String(endTime),
      ).toLocaleDateString(language, dateTimeOptions)}`;
      return result;
    },
    [language],
  );

  const imgCellTemplate = useCallback(
    (container: any, options: any) => {
      const user = allUsers?.find((user) => user.id === options.data.userId);
      renderProfileImage(container, user?.profilePicture || null, user, language);
    },
    [allUsers, language],
  );

  return (
    <>
      <ScrollView>
        <LoadPanel
          visible={isFetchingUsers || isFetchingClinicsForCurrentUser || isFetchingSchedules}
          position={{ my: 'center', at: 'center', of: `#workingHours` }}
          shading={true}
          shadingColor={'rgba(0,0,0,.32)'}
          height="100%"
        />
        <DataGrid
          id={'workingHours'}
          // height={'100%'}
          // width={'100%'}
          columnAutoWidth={true}
          dataSource={cloneDeep(allSchedules)}
        >
          <FilterRow visible={true} />
          <LoadPanelProp enabled={false} />
          <Column cellTemplate={imgCellTemplate} allowSorting={false} />
          <Column
            dataField={'userId'}
            caption={`${t('workingHours.user')}`}
            defaultSortOrder={'asc'}
            calculateCellValue={calculateUserDisplayExpr}
          />
          <Column dataField={'clinicId'} caption={`${t('workingHours.clinic')}`} calculateCellValue={getClinicName} />
          <Column
            dataField={'startTime'}
            caption={`${t('workingHours.schedulePeriod')}`}
            calculateCellValue={schedulePeriod}
          />
          <MasterDetail enabled={true} component={detailView} />
        </DataGrid>
      </ScrollView>
    </>
  );
};
