import { medAppApi as api } from '../medappApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/user/edit/${queryArg.userId}`,
        method: 'PUT',
        body: queryArg.userMultilangDto,
        params: { language: queryArg.language },
      }),
    }),
    registerApplicationUser: build.mutation<RegisterApplicationUserApiResponse, RegisterApplicationUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/user/application-user/register`,
        method: 'POST',
        body: queryArg.userMultilangDto,
      }),
    }),
    registerAdminUser: build.mutation<RegisterAdminUserApiResponse, RegisterAdminUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/user/admin-user/register`,
        method: 'POST',
        body: queryArg.adminUserWriteDto,
      }),
    }),
    addUserPicture: build.mutation<AddUserPictureApiResponse, AddUserPictureApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/user/addUserPicture`,
        method: 'POST',
        body: queryArg.body,
        params: { userId: queryArg.userId },
      }),
    }),
    getUserDetails: build.query<GetUserDetailsApiResponse, GetUserDetailsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/user/details/${queryArg.userId}`,
        params: { language: queryArg.language },
      }),
    }),
    getCurrentUser: build.query<GetCurrentUserApiResponse, GetCurrentUserApiArg>({
      query: () => ({ url: `/api/v1/user/current` }),
    }),
    getAllUser: build.query<GetAllUserApiResponse, GetAllUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/user/all`,
        params: { userType: queryArg.userType, language: queryArg.language },
      }),
    }),
    getAllUserMultiLang: build.query<GetAllUserMultiLangApiResponse, GetAllUserMultiLangApiArg>({
      query: (queryArg) => ({ url: `/api/v1/user/all-multilingual`, params: { userType: queryArg.userType } }),
    }),
    getSchedulesByUserId: build.query<GetSchedulesByUserIdApiResponse, GetSchedulesByUserIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/schedule/user/${queryArg.userId}` }),
    }),
    getSchedulesByUserIdAndWeek: build.query<GetSchedulesByUserIdAndWeekApiResponse, GetSchedulesByUserIdAndWeekApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/v1/schedule/user/${queryArg.userId}/week/${queryArg.week}`,
          headers: { timeZone: queryArg.timeZone },
        }),
      },
    ),
    getSchedulesByUserIdAndMonth: build.query<
      GetSchedulesByUserIdAndMonthApiResponse,
      GetSchedulesByUserIdAndMonthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/schedule/user/${queryArg.userId}/month/${queryArg.month}`,
        headers: { timeZone: queryArg.timeZone },
      }),
    }),
    getSchedulesByUserIdAndDate: build.query<GetSchedulesByUserIdAndDateApiResponse, GetSchedulesByUserIdAndDateApiArg>(
      {
        query: (queryArg) => ({
          url: `/api/v1/schedule/user/${queryArg.userId}/date/${queryArg.date}`,
          headers: { timeZone: queryArg.timeZone },
        }),
      },
    ),
    getClinicsForCurrentUser: build.query<GetClinicsForCurrentUserApiResponse, GetClinicsForCurrentUserApiArg>({
      query: (queryArg) => ({ url: `/api/v1/clinic/currentUser`, params: { lang: queryArg.lang } }),
    }),
    getAllClinicsByUser: build.query<GetAllClinicsByUserApiResponse, GetAllClinicsByUserApiArg>({
      query: (queryArg) => ({ url: `/api/v1/clinic/all/${queryArg.userId}` }),
    }),
    getCalendarForUserByPeriod: build.query<GetCalendarForUserByPeriodApiResponse, GetCalendarForUserByPeriodApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/calendar/${queryArg.userId}`,
        headers: { timeZone: queryArg.timeZone },
        params: { start_date: queryArg.startDate, end_date: queryArg.endDate },
      }),
    }),
    getCalendarForCurrentUserByPeriod: build.query<
      GetCalendarForCurrentUserByPeriodApiResponse,
      GetCalendarForCurrentUserByPeriodApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/calendar/${queryArg.startDate}/${queryArg.endDate}`,
        headers: { timeZone: queryArg.timeZone },
      }),
    }),
    getHangingAppointmentsByUserId: build.query<
      GetHangingAppointmentsByUserIdApiResponse,
      GetHangingAppointmentsByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/appointment/hanging/${queryArg.userId}` }),
    }),
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: (queryArg) => ({ url: `/api/v1/user/application-user/delete/${queryArg.userId}`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type UpdateUserApiResponse = /** status 200 OK */ UserDto;
export type UpdateUserApiArg = {
  userId: number;
  language?: string;
  userMultilangDto: UserMultilangDto;
};
export type RegisterApplicationUserApiResponse = /** status 200 OK */ object;
export type RegisterApplicationUserApiArg = {
  userMultilangDto: UserMultilangDto;
};
export type RegisterAdminUserApiResponse = /** status 200 OK */ object;
export type RegisterAdminUserApiArg = {
  adminUserWriteDto: AdminUserWriteDto;
};
export type AddUserPictureApiResponse = /** status 200 OK */ UserMultilangDto;
export type AddUserPictureApiArg = {
  userId: number;
  body: {
    file: Blob;
  };
};
export type GetUserDetailsApiResponse = /** status 200 OK */ ObjectRevisionDto[];
export type GetUserDetailsApiArg = {
  userId: number;
  language?: string;
};
export type GetCurrentUserApiResponse = /** status 200 OK */ UserMultilangDto;
export type GetCurrentUserApiArg = void;
export type GetAllUserApiResponse = /** status 200 OK */ UserDto[];
export type GetAllUserApiArg = {
  userType?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
  language?: string;
};
export type GetAllUserMultiLangApiResponse = /** status 200 OK */ UserMultilangDto[];
export type GetAllUserMultiLangApiArg = {
  userType?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type GetSchedulesByUserIdApiResponse = /** status 200 OK */ ScheduleDto[];
export type GetSchedulesByUserIdApiArg = {
  userId: number;
};
export type GetSchedulesByUserIdAndWeekApiResponse = /** status 200 OK */ ScheduleDto[];
export type GetSchedulesByUserIdAndWeekApiArg = {
  userId: number;
  week: number;
  timeZone?: string;
};
export type GetSchedulesByUserIdAndMonthApiResponse = /** status 200 OK */ ScheduleDto[];
export type GetSchedulesByUserIdAndMonthApiArg = {
  userId: number;
  month: number;
  timeZone?: string;
};
export type GetSchedulesByUserIdAndDateApiResponse = /** status 200 OK */ ScheduleDto[];
export type GetSchedulesByUserIdAndDateApiArg = {
  userId: number;
  date: string;
  timeZone?: string;
};
export type GetClinicsForCurrentUserApiResponse = /** status 200 OK */ ClinicMultiLangDto[];
export type GetClinicsForCurrentUserApiArg = {
  lang: string;
};
export type GetAllClinicsByUserApiResponse = /** status 200 OK */ number[];
export type GetAllClinicsByUserApiArg = {
  userId: number;
};
export type GetCalendarForUserByPeriodApiResponse = /** status 200 OK */ CalendarDto;
export type GetCalendarForUserByPeriodApiArg = {
  userId: number;
  startDate?: string;
  endDate?: string;
  timeZone?: string;
};
export type GetCalendarForCurrentUserByPeriodApiResponse = /** status 200 OK */ CalendarDto;
export type GetCalendarForCurrentUserByPeriodApiArg = {
  startDate: string;
  endDate: string;
  timeZone?: string;
};
export type GetHangingAppointmentsByUserIdApiResponse = /** status 200 OK */ AppointmentDto[];
export type GetHangingAppointmentsByUserIdApiArg = {
  userId: number;
};
export type DeleteUserApiResponse = /** status 200 OK */ object;
export type DeleteUserApiArg = {
  userId: number;
};
export type UserDto = {
  id?: number;
  firstName?: string;
  lastName?: string;
  username?: string;
  clinicIds?: number[];
  specialityIds?: number[];
  phoneNumber?: string;
  emailAddress?: string;
  address?: string;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
};
export type LocalizedStringDto = {
  language: string;
  value: string;
};
export type UserMultilangDto = {
  id?: number;
  firstName?: LocalizedStringDto[];
  lastName?: LocalizedStringDto[];
  clinicIds?: number[];
  specialityIds?: number[];
  username: string;
  phoneNumber?: string;
  emailAddress?: string;
  password?: string;
  passwordTemporary?: boolean;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
  internal?: boolean;
};
export type AdminUserWriteDto = {
  id?: number;
  firstName?: LocalizedStringDto[];
  lastName?: LocalizedStringDto[];
  clinicIds?: number[];
  specialityIds?: number[];
  username: string;
  phoneNumber: string;
  emailAddress: string;
  password?: string;
  passwordTemporary?: boolean;
  profilePicture?: string;
  type?: 'ADMIN' | 'MANAGER' | 'REGISTRATOR' | 'SPECIALIST' | 'ASSISTANT';
  internal?: boolean;
};
export type ObjectRevisionDto = {
  object?: object;
  revisionType?: string;
  revisionDate?: number;
  revisionId?: number;
  user?: UserDto;
};
export type WorkingHoursDto = {
  id?: number;
  clinicId?: number;
  scheduleId?: number;
  dayOfWeek?: 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
  startTime?: string;
  endTime?: string;
  breakStartTime?: string;
  breakEndTime?: string;
};
export type ScheduleDto = {
  id?: number;
  userId: number;
  clinicId: number;
  countryCode?: string;
  startTime: string;
  endTime: string;
  usualInitialAppointmentDuration?: number;
  usualSecondaryAppointmentDuration?: number;
  intervalBetweenAppointments?: number;
  workingHours?: WorkingHoursDto[];
};
export type ClinicMultiLangDto = {
  id?: number;
  name: LocalizedStringDto[];
  phoneNumber?: string;
  organizationId: number;
  addressId: number;
  countryCode?: string;
  logoFileName?: string;
  coverPhotoFileName?: string;
};
export type AppointmentDto = {
  id?: number;
  userId: number;
  patientId?: number;
  patientName?: string;
  phoneNumber?: string;
  status: 'SCHEDULED' | 'PENDING' | 'COMPLETED' | 'CANCELLED';
  appointmentType: 'INITIAL' | 'SECONDARY' | 'OPEN' | 'INSURANCE' | 'OTHER' | 'ABSENCE';
  startAt: string;
  endAt?: string;
};
export type CalendarDto = {
  schedules?: ScheduleDto[];
  appointments?: AppointmentDto[];
  specialists?: UserMultilangDto[];
};
export const {
  useUpdateUserMutation,
  useRegisterApplicationUserMutation,
  useRegisterAdminUserMutation,
  useAddUserPictureMutation,
  useGetUserDetailsQuery,
  useGetCurrentUserQuery,
  useGetAllUserQuery,
  useGetAllUserMultiLangQuery,
  useGetSchedulesByUserIdQuery,
  useGetSchedulesByUserIdAndWeekQuery,
  useGetSchedulesByUserIdAndMonthQuery,
  useGetSchedulesByUserIdAndDateQuery,
  useGetClinicsForCurrentUserQuery,
  useGetAllClinicsByUserQuery,
  useGetCalendarForUserByPeriodQuery,
  useGetCalendarForCurrentUserByPeriodQuery,
  useGetHangingAppointmentsByUserIdQuery,
  useDeleteUserMutation,
} = injectedRtkApi;
